.App {
  max-width: 843px;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #d9dadb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #09d3ac;
}

.ol-attribution {
  font-size: 12px;
}

.ol-attribution a {
  color: rgb(0 116 184);
  text-decoration: none;
}